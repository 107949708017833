
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDhmHQwjggTfIvxeSSfzDeyYR40EMVumv8",
    authDomain: "osrs-bonds.firebaseapp.com",
    projectId: "osrs-bonds",
    storageBucket: "osrs-bonds.appspot.com",
    messagingSenderId: "710459449311",
    appId: "1:710459449311:web:23cc0e80f56b4d5ac36e6a",
    measurementId: "G-BDEMGEKH34"
  };


const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;