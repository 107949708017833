import { useEffect, useState } from "react";
import firebaseApp from "./Firebase";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import dateFormat from "dateformat";
import sessionstorage from "sessionstorage";

const tickerToCountry = {
  "GBP": "Great Britain",
  "USD": "USA",
  "CAD": "Canada",
  "EUR": "Europe",
  "AUD": "Austrailia",
  "CHF": "Switzerland",
  "MXN": "Mexico",
  "PLN": "Poland",
  "SEK": "Sweeden",
  "JPY": "Japan",
  "SGD": "Singapore",
  "MYR": "Malaysia",
  "NOK": "Norway",
  "NZD": "New Zealand",
  "DKK": "Denmark",
  "ZAR": "South Africa",
  "COP": "Colombia",
  "INR": "India",
};

const Title = () => {
  return (
    <div className="text-center text-white pt-8">
      <h1 className="pb-2 text-5xl font-bold">Bond Calculator</h1>
      <p className="text-xl">Use a VPN</p>
    </div>
  );
}

const RenderSelect = ({options, data}) => {
  const [selected, setSelected] = useState("AUD");
  const onSelectChange = event => {
    setSelected(event.target.value);
  };

  const [prices, setPrices] = useState([]);
  useEffect(() => {
    const bondPrices = sessionstorage.getItem("bond-prices");
    if (bondPrices === null) {
      const ref = getFirestore(firebaseApp);
      const res = getDoc(doc(ref, 'metadata', "bond-prices"));
      res.then((d) => {
        setPrices(d.data());
        sessionstorage.setItem("bond-prices", JSON.stringify(d.data()));
      });
    } else {
      setPrices(JSON.parse(bondPrices));
    }
  }, []);

  if (Object.keys(prices).length > 0) {
    const translatedPrices = new Map();
    Object.keys(prices).forEach(key => {
      if (key !== selected) {
        translatedPrices.set(tickerToCountry[key], (prices[key] * (1 / data[selected][key])).toFixed(2));
      }
    });
    const sortedList = Object.fromEntries(new Map([...translatedPrices.entries()].sort((a, b) => a[1] - b[1])));

    return (
      <div className="text-white">
        <div>
          <select className="mt-2 mb-2 w-16 h-8 text-center border rounded bg-neutral-700" id="currency" onChange={onSelectChange}>
            {options.map((val) => <option key={val}>{val}</option>)}
          </select>
        </div>
        <div className="flex flex-col">
          <table className="">
            <thead className="font-bold text-lg">
              <tr>
                <th className="w-36">Location</th>
                <th className="w-36">{selected}</th>
              </tr>
            </thead>
            <tbody className="text-base">
              {
                Object.keys(sortedList).map((item, index) => <tr key={item}><td>{item}</td><td>{sortedList[item]}</td></tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  return (<></>);
}

const Selector = () => {
  const [currencies, setCurrencies] = useState(null);
  useEffect(() => {
    const date = new Date();
    const dateString = dateFormat(date, "yyyy-mm-dd");
    const currencyString = sessionstorage.getItem(dateString);
    if (currencyString === null) {
      const ref = getFirestore(firebaseApp);
      const res = getDoc(doc(ref, 'currencies', dateString));
      res.then((d) => {
        setCurrencies(d.data());
        sessionstorage.setItem(dateString, JSON.stringify(d.data()))
      });
    } else {
      setCurrencies(JSON.parse(currencyString));
    }
  }, []);

  if (currencies !== null) {
    return (
      <div className="text-center pt-16 flex flex-col items-center text-white">
        <h2>Select your local currency</h2>
        <RenderSelect options={Object.keys(currencies.currencies).sort()} data={currencies.currencies} />
      </div>
    );
  } else {
    return (
      <div className="text-center pt-16 flex flex-col items-center text-white">
        <p>Loading currency conversions...</p>
      </div>
    );
  }
}

const App = () => {
  return (
    <div className="flex flex-col h-screen bg-neutral-900">
      <Title />
      <Selector />
    </div>
  ); 
}

export default App;
